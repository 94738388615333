<template>
  <div>
    <v-btn small text color="secondary" @click="toggle" :disabled="disabled">
      {{selected ? 'Unselect All' : 'Select All' }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'CheckAll',
  data() {
    return {
      selected: false
    }
  },
  props: {
    disabled: Boolean,
    fields: {
      type: Array,
      default: () => []
    },
  },
  methods: {
    toggle() {
      this.selected = !this.selected;

      this.fields.forEach(fieldName => {
        if (this.$store.getters.showControl('additionalForm', fieldName)) {
          this.$store.commit('updateFormControl', {
            module: 'additionalForm',
            name: fieldName,
            value: this.selected
          })
        }
      })
    }
  }
}
</script>
